import React from 'react';
import './ServicesHero.css';
import service from '../../../assets/servicesImg.png'
import GetQuote from '../../baseComponents/GetQuote';
const ServicesHero = () => {


    return (
        <>
            <div className="service-hero-container">
                <div className="service-hero-header">
                    <h1>Smarter <span style={{ color: '#F9A410' }}>Energy</span> Decisions
                with Tailored Data Analysis</h1>
                </div>

                <div className="service-hero-text">
                    <h6>Sonaroid Analytics bridges the gap between you and actionable energy
                insights, empowering you to make informed decisions that drive progress.</h6>
                </div>


                <GetQuote />

                <div>
                    <img src={service} alt="discImg" />
                </div>

            </div>


        </>
    )
}

export default ServicesHero
