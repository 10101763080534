import React, { useState } from 'react';
import Logo from "../../assets/Logo.svg";
import LogoAlt from "../../assets/logoAlt.svg";
// import { IoIosArrowDown } from "react-icons/io";
// import { IoIosArrowUp } from "react-icons/io";
// import { useLocation } from 'react-router-dom';
import { CiMenuBurger } from "react-icons/ci";
import './Navbar.css'

import EarlyAccessBtn from '../baseComponents/EarlyAccessBtn';
const Navbar = ({ background, alternative, bottomBorder }) => {
    // const [isOpen, setIsOpen] = useState(false);
    // const location = useLocation();
    // const toggleDropdown = () => {
    //     setIsOpen(!isOpen);
    // };
    const bgStyle = {
        background: background,
        borderBottom: bottomBorder
    };
    // const isProductsBlue = location.pathname.includes("/consumer") || location.pathname.includes("/contributor");

    const currentPath = window.location.pathname;


    const isActive = (path) => {
        return currentPath === path ? "active" : "";
    };

    const [sidebarVisible, setSidebarVisible] = useState(false);

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible); // Toggle the sidebar visibility
    };
    return (
        <div className="nav-container" style={bgStyle}>

            <nav className={`${alternative === "active" ? "nav-links alt" : "nav-links"}`}>
                {alternative === "active" ?
                    <a href="/#">
                        <img src={LogoAlt} alt="logo"
                            style={{
                                width: '100%',
                                height: 'auto',
                                maxWidth: '121px',

                            }}
                        />
                    </a>
                    :
                    <a href="/#">
                        <img src={Logo} alt="logo"
                            style={{
                                width: '100%',
                                height: 'auto',
                                maxWidth: '121px',

                            }}
                        />
                    </a>
                }


                <ul>
                    <li><a href="/about" className={isActive("/about")}>About</a></li>

                    {/* <li className="nav-products-container">
                        <div className={`${(isOpen || isProductsBlue) ? "nav-products-two" : "nav-products"}`}>
                            <a href="/#" onClick={(e) => { e.preventDefault(); toggleDropdown(); }}
                                className={`${alternative === "active" && !isOpen && !isProductsBlue ? "nav-products alt" : ""}`}
                            > Products</a>

                            {isOpen ? (
                                <IoIosArrowUp onClick={() => toggleDropdown()}
                                    className={`${alternative === "active" ? "nav-products-icon alt" : "nav-products-icon"}`}
                                />
                            ) : (
                                    <IoIosArrowDown onClick={() => toggleDropdown()}
                                        className={`${alternative === "active" ? "nav-products-icon alt" : "nav-products-icon"}`}
                                    />
                                )}
                        </div>

                        {isOpen && (
                            <div className="nav-options">
                                <a href="/consumer">For Consumers</a>
                                <a href="/contributor">For Contributors</a>

                            </div>
                        )}

                    </li> */}
                    <li><a href="/services" className={isActive("/services")}>Services</a></li>
                    {/* <li><a href="/faqandpricing" className={isActive("/faqandpricing")}>Pricing</a></li> */}
                    <li><a href="/contact" className={isActive("/contact")}>Contact</a></li>
                </ul>


            </nav>
            <div>
                {/* <button>Get Early Access</button> */}
                <EarlyAccessBtn
                    height="44px"
                    padding="0em 1em"
                />
            </div>
            <CiMenuBurger className={`${alternative === "active" ? "menu-button" : "menu-button alt"}`} onClick={() => toggleSidebar()} />

            <ul className={`sidebar ${sidebarVisible ? 'visible' : ''}`}>

                <li><a href="/about" className={isActive("/about")}>About</a></li>

                {/* <li className="nav-products-container">
                    <div className={`${(isOpen || isProductsBlue) ? "nav-products-two" : "nav-products"}`}>
                        <a href="/#" onClick={(e) => { e.preventDefault(); toggleDropdown(); }}
                            style={{ color: "#FFFFFF", fontWeight: 500 }}
                            className={`${alternative === "active" && !isOpen && !isProductsBlue ? "nav-products alt" : ""}`}
                        > Products</a>

                        {isOpen ? (
                            <IoIosArrowUp onClick={() => toggleDropdown()}
                                style={{ color: "#FFFFFF" }} className={`${alternative === "active" ? "nav-products-icon alt" : "nav-products-icon"}`}
                            />
                        ) : (
                                <IoIosArrowDown onClick={() => toggleDropdown()}
                                    style={{ color: "#FFFFFF" }} className={`${alternative === "active" ? "nav-products-icon alt" : "nav-products-icon"}`}
                                />
                            )}
                    </div>

                    {isOpen && (
                        <div className="nav-options">
                            <a style={{ padding: '0em' }} href="/consumer">For Consumers</a>
                            <a style={{ padding: '0em' }} href="/contributor">For Contributors</a>


                        </div>
                    )}

                </li> */}
                <li><a href="/services" className={isActive("/services")}>Services</a></li>
                {/* <li><a href="/faqandpricing" className={isActive("/faqandpricing")}>Pricing</a></li> */}
                <li><a href="/contact" className={isActive("/contact")}>Contact</a></li>
            </ul>



        </div>
    )
}

export default Navbar
