import React from 'react';
import './MissionVision.css';
import mission from '../../../assets/mission.svg';
import vision from '../../../assets/vision.svg';

const MissionVision = () => {
    return (
        <div className="mission-container">
            <div className="mission-header">
                <h1>We are passionate about supporting the growth
            of the energy industry.</h1>
            </div>


            <div className="mission-content">
                <div style={{ background: "#FEF0D8" }}>
                    <img src={mission} alt="mission" />
                    <h2>Our Mission</h2>
                    <hr />
                    <h3>To drive positive decision-making in the African energy industry.</h3>
                    <h5>Leveraging technology, we are providing near-real-time authentic data and world-class insights to the African energy industry.</h5>
                </div>
                <div style={{ background: "#C3E1FF" }}>
                    <img src={vision} alt="vision" />
                    <h2>Our Vision</h2>
                    <hr />
                    <h3>To build the future of Africa with the power of knowledge.</h3>
                    <h5>We see a future where Africa dominates the energy industry and we believe access to information will be a key driver to that future</h5>
                </div>
            </div>
        </div>
    )
}

export default MissionVision
