import React from 'react'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import AboutPage from './components/AboutPage/AboutPage';
import Services from './components/Services/Services';
// import FAQandPricing from './components/FAQandPricing/FAQandPricing';
import Contact from './components/Contact/Contact';
import TermsOfService from './components/TermsOfService/TermsOfService';
import Privacy from './components/Privacy/Privacy';
// import Consumer from './components/Consumer/Consumer';
// import Contributor from './components/Contributor/Contributor';

function App() {


  return (

    <Router>

      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path ="/termsofservice" element={<TermsOfService/>} />
          <Route path ="/privacypolicy" element={<Privacy/>} />
          {/* <Route path="/faqandpricing" element={<FAQandPricing />} /> */}
          {/* <Route path="/consumer" element={<Consumer />} />
          <Route path="/contributor" element={<Contributor />} /> */}

        </Routes>
      </div>
    </Router>
  )
}

export default App
