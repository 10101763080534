import React from 'react'
import ContactHero from './ContactHero/ContactHero';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer'
import ContactBody from './ContactBody/ContactBody';
import { Helmet } from 'react-helmet-async';
const Contact = () => {
    return (
        <div>
            <Helmet>
                <title>Contact</title>
                <meta name="description" content="We will be happy to assist you." />
                <link rel="canonical" href="https://sonaroid.io/contact" />
            </Helmet>
            <Navbar
                background="#FFFFFF"
                alternative="active"
                bottomBorder="1px solid #ECE9E4"
            />
            <ContactHero />
            <ContactBody />
            <Footer />
        </div>
    )
}

export default Contact
