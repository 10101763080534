import React from 'react'
import EarlyAccessBtn from '../../baseComponents/EarlyAccessBtn';
import './GetStarted.css'
const GetStarted = () => {
    return (
        <div className="get-started-container">
            <div className="get-started-main">
                <div className="get-started-left">
                    <p>Ready to get started? Reserve your spot now.</p>
                </div>
                <div className="get-started-right">
                    <p>We're launching soon! Sign up to be the first to experience our web application.</p>
                    <EarlyAccessBtn
                        height='56px'
                        padding="0em 2.25em"
                    />
                </div>
            </div>
        </div>
    )
}

export default GetStarted
