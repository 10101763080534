import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer'
import ServicesHero from './ServicesHero/ServicesHero';
import ServicesBody from './ServicesBody/ServicesBody';
import ServicesDiscover from './ServicesDiscover/ServicesDiscover';
import { Helmet } from 'react-helmet-async'
const Services = () => {
    return (

        <div>
            <Helmet>
            <title>Services</title>
            <meta name="description" content="Sonaroid Analytics bridges the gap between you and actionable energy
                insights, empowering you to make informed decisions that drive progress." />
                <link rel="canonical" href="https://sonaroid.io/services"/>
            </Helmet>
            <Navbar
                background="#020712"
                alternative={null}
                bottomBorder="1px solid rgba(39, 43, 50, 1)"
            />
            <ServicesHero />
            <ServicesBody />
            <ServicesDiscover />
            <Footer />
        </div>
    )
}

export default Services
