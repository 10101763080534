// import React, { useState, useEffect, useRef } from 'react';
import React from 'react';
import './Insights.css';
// import { IoIosArrowRoundForward } from "react-icons/io";
// import consumer1 from '../../../assets/consumer1.svg';
// import consumer2 from '../../../assets/consumer2.svg';
// import consumer3 from '../../../assets/consumer3.svg';
// import contributor1 from '../../../assets/contributor1.svg';
// import contributor2 from '../../../assets/contributor2.svg';
// import contributor3 from '../../../assets/contributor3.svg';
import flowIcon from '../../../assets/flowIcon.svg';
import flowIcon2 from '../../../assets/flowIcon2.svg';
import flowIcon3 from '../../../assets/flowIcon3.svg';
import flowLine from '../../../assets/flowLine.svg';
import insightImg from '../../../assets/giphy.gif';
const Insights = () => {
    // const [showContributor, setShowContributor] = useState(false);

    // const contributorRef = useRef(null);



    // useEffect(() => {
    //     const observer = new IntersectionObserver(entries => {
    //         entries.forEach(entry => {
    //             if (entry.isIntersecting) {
    //                 setShowContributor(true);
    //             } else {
    //                 setShowContributor(false);
    //             }
    //         });
    //     }, { threshold: 0.5 });

    //     const currentRef = contributorRef.current; // Copy to a local variable

    //     if (currentRef) {
    //         observer.observe(currentRef);
    //     }

    //     // Cleanup observer
    //     return () => {
    //         if (currentRef) {
    //             observer.unobserve(currentRef);
    //         }
    //     };
    // }, []); // Empty dependency array means this effect runs once after mount



    return (
        <section className="insights-container">
            <div>
                {/* <div className="insights-header">
                    <h1>With Sonaroid’s Insights, Everybody Wins!</h1>
                    <p>How can you use Sonaroid?</p>
                </div> */}

                {/* <div className="insights-section-one">
                    <div className="insights_consumer_contributor">
                        <div onClick={() => setShowContributor(false)} className={showContributor ? "insights_consumer" : "insights_consumer clicked"} >
                            <h3 className={showContributor ? "" : "highlight_consumer"} >Consumer</h3>
                            <p>View, share and download news, data, maps and reports.</p>
                            <div>
                                <a href="/#">Learn more</a>
                                <IoIosArrowRoundForward className="icon" />
                            </div>
                        </div>
                        <div onClick={() => setShowContributor(true)} className={showContributor ? "insights_contributor clicked" : "insights_contributor"}  >
                            <h3 className={showContributor ? "highlight_consumer" : ""}>Contributor</h3>
                            <p>Get paid as you create data tables and reports. </p>
                            <div>
                                <a href="/#">Learn more</a>
                                <IoIosArrowRoundForward className="icon" />
                            </div>

                        </div>
                    </div>
                    {showContributor ? (

                        <div className="insights_user_feature">
                            <div>
                                <img src={contributor1} alt="icon" />
                                <p>Contribute your unique perspective.</p>
                            </div>
                            <div>
                                <img src={contributor2} alt="icon" />
                                <p>Get paid for  knowledge shared.</p>
                            </div>
                            <div>
                                <img src={contributor3} alt="icon" />
                                <p>Connect with industry professionals.</p>
                            </div>
                        </div>
                    ) : (
                            <div className="insights_user_feature">
                                <div>
                                    <img src={consumer1} alt="icon" />
                                    <p>Access reliable energy data and analytics.</p>
                                </div>
                                <div>
                                    <img src={consumer2} alt="icon" />
                                    <p>Stay up to date with industry trends.</p>
                                </div>
                                <div>
                                    <img src={consumer3} alt="icon" />
                                    <p>Invest with confidence with our analysis.</p>
                                </div>
                            </div>
                        )}
                </div> */}
                {/* <div ref={contributorRef}>

                </div> */}
            </div>

            <div className="insights-section-two">
                <div className="insights-sec-two-header">
                    <h1>Get the data that you need in 3 easy steps</h1>
                    <p>As a consumer, you can experience the power of Sonaroid Analytics risk-free! Get a free 7-day trial with advanced features. Cancel anytime. </p>
                </div>

                <div className="insights-section-three">
                    <div className="insights-data-flow-cont">
                        <div className="insights-data-flow">
                            <img src={flowIcon} alt="icon" />
                            <div>
                                <h4>Sign Up</h4>
                                <p>Choose your account type and set preferences.</p>
                            </div>
                        </div>

                        <div className="line-flow"><img src={flowLine} alt="flow" /></div>


                        <div className="insights-data-flow">
                            <img src={flowIcon2} alt="icon" />
                            <div>
                                <h4>Search</h4>
                                <p>Find the resources you need.</p>
                            </div>
                        </div>

                        <div className="line-flow"><img src={flowLine} alt="flow" /></div>


                        <div className="insights-data-flow">
                            <img src={flowIcon3} alt="icon" />
                            <div>
                                <h4>Download</h4>
                                <p>Make your datasets available anytime.</p>
                            </div>
                        </div>

                    </div>

                    <div className="insights-img-box" >
                        <img src={insightImg} alt="insight" />

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Insights
