import React from 'react';
import './CoreValues.css';
import value1 from '../../../assets/value1.svg'
import value2 from '../../../assets/value2.svg'
import value3 from '../../../assets/value3.svg'
import value4 from '../../../assets/value4.svg'
const CoreValues = () => {
    return (
        <div className="values-container">
            <h1>Our Core Values</h1>
            <div className="values-content">
                <div>

                    <img src={value1} alt="valueimg" />
                    <h4>Innovation</h4>
                    <p>“There is more than one path to the market.” - Yoruba Proverb</p>
                    <h5>We push the boundaries of data analysis, constantly seeking new ways to maximize the potential of African energy data.</h5>
                </div>
                <div>
                    <img src={value2} alt="valueimg" />
                    <h4>Community</h4>
                    <p>“A little rain each day will fill the rivers to overflowing.” - Malagasy proverb</p>
                    <h5>We foster collaboration and knowledge sharing within the African energy sector, driving progress together.</h5>
                </div>
                <div>
                    <img src={value3} alt="valueimg" />
                    <h4>Integrity</h4>
                    <p>“Good name better pass gold and silver.” - Nigerian Pidgin proverb</p>
                    <h5>We stand by the accuracy and transparency of our data and insights, earning the trust of our users.</h5>
                </div>
                <div>
                    <img src={value4} alt="valueimg" />
                    <h4>Reliability</h4>
                    <p>“Words are like bullets; if they escape, you can’t catch them again.” - Senegalese proverb</p>
                    <h5>You can depend on Sonaroid to deliver consistent, high-quality data and analytics, empowering informed decision-making.</h5>
                </div>
            </div>
        </div>
    )
}

export default CoreValues
