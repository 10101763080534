import React from 'react'
import './Goals.css'
const Goals = () => {
    return (
        <div className="goals-container">
            <h1>Our Goals</h1>
            <div className="goals-content">
                <div>
                    <h5>01</h5>
                    <div>
                        <h4>Accessible Data </h4>

                    </div>

                    <p>We bridge the gap between the known and the unknown and we believe everybody should be able to walk through that bridge...regardless of their size or the size of their pockets.</p>

                </div>
                <div>
                    <h5>02</h5>
                    <div>
                        <h4>Industry Growth </h4>

                    </div>

                    <p>Through advanced analytics, we provide actionable insights that empower our users to make the best decision that assures their growth.</p>

                </div>
                <div>
                    <h5>03</h5>
                    <div>
                        <h4>Collaborative Environment</h4>

                    </div>

                    <p>We aim to display the immense strength that comes with collaboration by facilitating the seamless flow of information among key stakeholders in the energy industry.</p>

                </div>
            </div>
        </div>
    )
}

export default Goals
