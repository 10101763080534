import React from 'react';
import EarlyAccessBtn from '../../baseComponents/EarlyAccessBtn';
import './ServicesDiscover.css';
import discover from '../../../assets/modalimage.png'
const ServicesDiscover = () => {
    return (
        <div className="services-discover-cont">

            <div className="services-discover-left">
                <div className="services-discover-text">
                    <h1>Discover a world of data and
                     insights through our central
                    repository.</h1>
                </div>

                <h5>Create a free account to gain exclusive access.</h5>
                <EarlyAccessBtn
                    height='56px'
                    padding="0em 2.25em"
                />
            </div>
            <div className="services-discover-left">
                <img src={discover} alt="discover" />
            </div>

        </div>
    )
}

export default ServicesDiscover
