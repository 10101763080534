import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import './Privacy.css'
const Privacy = () => {
  return (
    <div>
      <Navbar
        background="#020712"
        alternative={null}
        bottomBorder="1px solid rgba(39, 43, 50, 1)"
        />
          <div className='policy_container'>
                <h1>Privacy Policy</h1>
                
                <br />
                <br />
                <p>
                This Website Privacy Policy (the "Policy") explains how Sonaroid Analytics Technologies Limited ("Sonaroid", "we", "our" or "us") uses and discloses your personal information collected when you visit our website.

                </p>

                <br />
                <p>
                This Policy is a legally binding document between you and Sonaroid. By clicking on the “Accept” button or using the website, you agree to be bound by the terms of this Policy. This Policy applies only to the personal information collected on our website.
                </p>
                
                <br />
                <br />

                <h3>INFORMATION WE COLLECT</h3>
                <br />

                <p>
                We collect your personal information i.e. information that identifies you as an individual, which includes your name, phone number, email address, information about your business or employer (as applicable), IP address, geographical location, browser type, browser version, operating system, duration and number of visits, website navigation, and other statistics. 
                </p>

                <br />
                <br />

                <h3>
                USE OF COOKIES
                </h3>
                <br />
                <p>
                Personal information we collect is typically by the use of cookies (or a similar technological tool) to help us to identify and track our website users upon each visit, understand and save their preferences for subsequent visits, compile data about site traffic and interaction, in order to provide a better user experience. In addition, when you register on our website, subscribe to our newsletter, respond to a survey or complete a fill, we also collect your personal information.

                </p>

                <br />
                <br />

                <h3>
                THE USE TO WHICH WE PUT YOUR PERSONAL INFORMATION
                </h3>
                <br />
                  <p>
                  We use your personal information for any of the following. To:

                  </p>
                    <br />
                  <div className='indent'>
                    <ul>
                       <li>manage the website;</li>
                        <br />
                        <li>create and manage your user account;</li>
                        <br />
                        <li>refine, evaluate, and enhance our business products and services, particularly to provide you with comprehensive and updated information in the energy industry;</li>
                        <br />
                        <li>maintain and improve our business relationship with you;</li>
                        <br />
                        <li>resolve any query, complaint, or feedback you may have;</li>
                        <br />
                        <li>carry out research, surveys, or promotions, where necessary;</li>
                        <br />
                        <li>prevent and detect fraudulent activities;</li>
                        <br />
                        <li>prevent and detect technical issues; and</li>
                        <br />
                        <li>comply with legal and regulatory requirements.</li>
                    </ul>
                  </div>

                  <br />
                  <br />

                  <h3>
                  WHO WE SHARE YOUR PERSONAL INFORMATION WITH
                  </h3>

                  <br />
                  <p>
                  We will not sell, exchange, trade, or transfer to third parties, your personal information, except as stated in this Policy.  Your personal information also may be disclosed to:
                  </p>
                  <br />
                  <div className='indent'>
                    <ul>
                      <li>
                      The website is designed to provide a one-stop marketplace for every data and information in the energy industry including market trends, news and operations. Thus, information may be made available to you in collaboration with our trusted third-party partners as far as they agree to keep your personal information confidential.  
                      </li>
                      <br />
                      <li>
                      We may disclose your personal information in compliance with requisite laws, to protect our internal business policies;

                      </li>
                      <br />
                      <li>
                      professional advisors including lawyers, IT personnel, business consultants, etc, on a need-to-know basis;

                      </li>
                      <br />
                      <li>
                      our employees on a need-to-know basis;
                      </li>
                      <br />
                      <li>
                      companies or other organisations that we have employed to renders services on our behalf. For example, analytics providers, web-hosting entities, mailing vendors, and information technology providers;

                      </li>
                      <br />
                      <li>
                      companies or organisations you have requested us to or accepted that we may share your personal information with;

                      </li>
                      <br />
                      <li>
                      a law enforcement agency, court, or regulatory authority or other third party where we reasonably believe it is necessary to comply with a legal or regulatory obligation, to protect your rights, the rights of any third party or individuals' personal safety, or to detect, prevent, fight fraud, security or safety issues; or

                      </li>
                      <br />
                      <li>
                      any third party that acquires, or to which we transfer, all of our assets and business. Where such a sale or transfer occurs, we will reasonably ensure that the acquiring entity uses your personal information in a manner that is consistent with this Policy.

                      </li>
                    </ul>
                  </div>

                  <br />
                  <br />

                  <h3>
                  HOW LONG WE WILL KEEP YOUR PERSONAL INFORMATION

                  </h3>

                  <br />
                  <p>
                  We will keep your personal information as long as they are reasonably needed to render our services to you, to defend our legal rights or as otherwise required by relevant laws. Otherwise, we will destroy the personal information. However, even if we destroy the data, it may remain in our archives for legal or regulatory purposes. 

                  </p>

                  <br />
                  <br />

                  <h3>
                  YOUR RIGHTS

                  </h3>

                  <br />
                  <p>
                  Your rights in relation to your personal information include the following:

                  </p>
                  <br />
                  <div className='indent'>
                    <ul>
                      <li>Right to access your personal information;</li>
                      <br />
                      <li>Right to have any inaccurate personal information amended;</li>
                      <br />
                      <li>Right to request the erasure of your personal information;</li>
                      <br />
                      <li>Right to restrict our use of your personal information;</li>
                      <br />
                      <li>Right to authorize us to move, copy, or transfer your personal information.</li>
                    </ul>
                  </div>
                  <br />
                  <br />


                  <h3>
                  HOW WE PROTECT YOUR PERSONAL INFORMATION

                  </h3>

                  <br />
                  <p>
                  We undertake reasonably necessary security measures to protect against misuse, unauthorised access to, alteration, disclosure or destruction of your personal information. These include use of secured firewalls, password protection of servers and periodic internal reviews of our information collection, storage and processing practices. However, we cannot guarantee a foolproof protection of your personal information.

                  </p>

                  <br />
                  <br />

                  <h3>
                  THIRD PARTY LINKS

                  </h3>
                  <br />
                  <p>
                  Our website may contain third party advertising or hyperlinks to other websites. We do not disclose your personal information to these websites. We therefore exclude any liability for loss that you may suffer as a result of the content and activities of these linked websites.  

                  </p>
                  <br />
                  <br />

                  
                  <h3>
                  REVIEW OF THIS PRIVACY POLICY
                  </h3>
                  <br />
                  <p>
                  We reserve the right to occasionally review this Policy. If we make significant changes to it, we will communicate providing a prominent notice on the website or by notifying you through your provided email address. Should you continue to use our website after any review, it will be deemed an acknowledgment of the revised policy and you will be bound by it. However, if you do not agree with the changes, please do not continue to use the website. 

                  </p>

                  <br />
                  <br />

                  <h3>
                  GOVERNING LAW

                  </h3>
                  <br />
                  <p>
                  This Policy will be governed by the laws of the Federal Republic of Nigeria.
                  </p>

                  <br />
                  <br />

                  <h3>
                  TERMS OF SERVICE

                  </h3>
                  <br />
                  <p>
                  Please visit our <a style={{color:"blue",textDecoration:'none'}} href="/termsofservice">Terms of Service</a> which establishes the terms governing the services we offer.
                  </p>
                  <br />
                  <br />

                  <h3>
                  CONTACT US 
                  </h3>
                  <br />
                  <p>
                  If you have any questions about this Policy, please contact us via:
                  </p>
                  <br />
                  <p>
                  Email: intelligence@sonaroid.io
                  
                  </p>
                  <br />
                  <p>
                  Telephone: +234 81 7607 4520
                  </p>
                  


             </div>
        <Footer/>
    </div>
  )
}

export default Privacy
