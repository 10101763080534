import React from 'react';
import './ProblemSection.css';
import close from '../../../assets/multiply-circle.svg';

const ProblemSection = () => {
    return (
        <div className="problem-container">
            <div className="problem-container-1">
                <div className="problem-grid-one">
                    <h3>The Problem</h3>
                    <p>What does a world without access to data look like? </p>

                </div>
                <div className="problem-grid-two">
                    <div>
                        <img src={close} alt="close" />
                        <p>Misallocation of Resources</p>
                    </div>
                    <div>
                        <img src={close} alt="close" />
                        <p>Inefficient Operations</p>
                    </div>
                    <div>
                        <img src={close} alt="close" />
                        <p>Unrealistic Goals and Targets</p>
                    </div>
                    <div>
                        <img src={close} alt="close" />
                        <p>Poor Investor Confidence</p>
                    </div>


                </div>
            </div>

            <div className="problem-container-2">
                {/* <div className="problem-grid-three">
                    <img src={video} alt="video" style={{ width: '100%' }} />
                </div> */}
                <div className="video-responsive">
                    <iframe src="https://www.youtube.com/embed/hMuFO9nhtfA" width="560" height="315" title="A YouTube video" frameborder="0" allowfullscreen></iframe>
                </div>
                <div className="problem-grid-four">

                    <h1>75 <span>%</span></h1>
                    <p>of the world's population without access to energy live in Africa</p>

                    {/* <div className="source-info">
                        <h4 class="horizontal-line"> </h4>
                        <p>Source of Information</p>
                    </div> */}
                </div>
            </div>

        </div>
    )
}

export default ProblemSection
