import React from 'react';
import './Footer.css';
// import { HiOutlineMail } from "react-icons/hi";
import logo from '../../assets/logoFooter.svg';
import fb from '../../assets/Fb.svg';
import Ig from '../../assets/Ig.svg';
import Ln from '../../assets/Ln.svg';
import Tw from '../../assets/Tw.png';
const Footer = () => {
    return (
        <footer className="footer">

            <div className="footer_main_content">

                <div className="footer-link">
                    <h4>Company</h4>
                    <ul>
                        <li><a href="/about">About</a></li>
                        <li><a href="/services">Services</a></li>
                        {/* <li><a href="/#">Blog</a></li> */}
                        {/* <li className="footer-link-career">
                            <a href="/#">Careers</a>
                            <p>We're hiring!</p>
                        </li> */}
                    </ul>

                </div>

                <div className="footer-link">
                    <h4>Our Solutions</h4>
                    <ul>
                        {/* <li><a href="/consumer">For Consumers</a></li>
                        <li><a href="/contributor">For Contributors</a></li> */}

                        <li><a href="/services">Get a Quote</a></li>
                    </ul>

                </div>

                <div className="footer-link">
                    <h4>Support</h4>
                    <ul>
                        {/* <li><a href="/#">Help centre</a></li> */}
                        {/* <li><a href="/faqandpricing">FAQ</a></li> */}

                        <li><a href="/contact">Contact</a></li>
                    </ul>

                </div>


                <div className="footer-link">
                    <h4>Legal & Support</h4>
                    <ul>
                        <li><a href="/privacypolicy">Privacy Policy</a></li>
                        <li><a href="/termsofservice">Terms and Conditions</a></li>
                    </ul>


                </div>
            </div>

            <div className="footer-subscribe">
                {/* <div className="footer-sub-main">
                    <p>Be the first to receive recent updates, articles, and valuable materials.</p>
                    <div className="footer-sub-input">
                        <div>
                            <HiOutlineMail style={{ fontSize: '20px', color: 'rgba(184, 179, 167, 1)' }} />
                            <input
                                type="email"
                                placeholder="Email address"
                            />
                        </div>
                        <button>
                            Subscribe
                        </button>
                    </div>
                </div> */}
            </div>

            <div style={{ borderTop: '1px solid rgba(39, 43, 50, 1)', marginBottom: '30px' }}></div>

            <div className="footer-bottom">
                <img src={logo} alt="logo" />
                <p>© 2024 Sonaroid. All rights reserved.</p>
                <div>
                    <a href="https://www.linkedin.com/company/sonaroid-analytics/"><img src={Ln} alt="LinkedIn" /></a>
                    <a href="https://www.facebook.com/profile.php?id=61555884914663"><img src={fb} alt="Facebook" /></a>
                    <a href="https://www.instagram.com/sonaroid_io/"><img src={Ig} alt="Instagram" /></a>
                    <a href="https://x.com/sonaroid_io"><img src={Tw} alt="Twitter" /></a>
                </div>


            </div>
        </footer>
    )
}

export default Footer
