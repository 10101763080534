import React from 'react'
import { FaCheckCircle } from "react-icons/fa";
import News from '../../../assets/newsImg.png';
import Auth from '../../../assets/authentic.png';
import Report from '../../../assets/report.png';
import Maps from '../../../assets/maps.png'
import './SolutionSection.css'
const SolutionSection = () => {
    return (
        <section className="solution-container">

            <div className="solution-header">

                <h3>Our Solution</h3>

                <p>We are maintaining a central
                repository that provides access to
                 <span className="underline-bold"> near-real-time </span> energy-related data
                and analytics.</p>


            </div>

            <div className="solution-body">
                <div className="solution-box-one">
                    <div>
                        <h1>News/Articles</h1>
                        <p>Stay informed as you dive into the latest insights and
                        trends shaping Africa's energy landscape. Our curated
                         news and articles keep you ahead of the curve.</p>
                    </div>

                    <div className="box-one-img" >
                        <img src={News} alt="bx1" />
                    </div>

                </div>

                <div className="solution-box-two">
                    <img src={Auth} alt="bx2" />

                    <div>
                        <h1>Authentic Data</h1>
                        <p>Sonaroid Analytics utilizes rigorous verification
                        methods to ensure the accuracy and reliability of
                        our data.</p>

                        <div className="solution-btn-cont">
                            <div className="solution-btn">
                                <FaCheckCircle color="green" />
                                <p>Accuracy Guaranteed</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div >


            <div className="solution-body">
                <div className="solution-box-three">

                    <img src={Report} alt="bx3" />
                    <div>
                        <h1>Industry Reports</h1>
                        <p>Explore our online library of in-depth reports,
                        insightful articles, and concise factsheets.
                        Share and download reports and papers.</p>
                        <div className="solution-btn-cont">
                            <div className="solution-btn">
                                <FaCheckCircle color="green" />
                                <p>Reliability Guaranteed</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="solution-box-four">
                    <div>
                        <h1>Data Visualizations</h1>
                        <p>Explore interactive maps and dynamic dashboards that
                        bring African energy data to life. Gain a clear picture of the
                    energy landscape and make informed decisions.</p>
                    </div>

                    <img src={Maps} alt="bx4" />
                </div>


            </div>
        </section >
    )
}

export default SolutionSection
