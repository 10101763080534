import React from 'react';
import EarlyAccessBtn from '../../baseComponents/EarlyAccessBtn';
import './ContactBody.css';
import imageOne from '../../../assets/modalimage.png';

const ContactBody = () => {
    return (
        <div className="contact-body-container">
            <div>
                <div className="contact-body-header">
                    <h1>Our application
                     offers deeper
                    analytics.</h1>
                </div>

                <div className="contact-body-content">
                    <h5>Create a free account to gain exclusive</h5>
                    <h5>access.</h5>
                </div>

                <EarlyAccessBtn
                    height='56px'
                    padding="0em 2.25em"
                />
            </div>

            <div className="contact-body-img">
                <img src={imageOne} alt="fleximg" />
            </div>

        </div>
    )
}
export default ContactBody