import React from 'react'
import Navbar from '../Navbar/Navbar';
import AboutHeader from './AboutHeader/AboutHeader';
import MissionVision from './MissionVison/MissionVision';
import CoreValues from './CoreValues/CoreValues';
import GetStarted from '../LandingPage/GetStarted/GetStarted';
import Footer from '../Footer/Footer';
import Goals from './Goals/Goals';
import Carousel from './AboutHeader/Carousel/Carousel';
import { Helmet } from 'react-helmet-async';
const AboutPage = () => {

    return (
        <div>
            <Helmet>
                <title>About</title>
                <meta name="description" content="We are changing the narrative of African energy." />
                <link rel="canonical" href="https://sonaroid.io/about" />
            </Helmet>
            <Navbar
                background="#FFFFFF"
                alternative="active"
                bottomBorder="1px solid #ECE9E4"
            />
            <AboutHeader />
            <Carousel />

            <MissionVision />
            <CoreValues />
            <Goals />
            <GetStarted />
            <Footer />
        </div>
    )
}

export default AboutPage
