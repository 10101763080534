import React, { useState } from 'react';
import './ContactHero.css';
// import img1 from '../../../assets/contactLocation.svg'
import img2 from '../../../assets/contactPhone.svg'
import img3 from '../../../assets/contactMail.svg'
import axios from 'axios';
import coffee from '../../../assets/Coffee.png';
const ContactHero = () => {
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const maxWords = 150;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [email, setEmail] = useState("")
    const [fullName, setFullName] = useState("")
    const [showSuccess, setShowSuccess] = useState(false);

    const handleChange = (event) => {
        const inputValue = event.target.value;
        const words = inputValue.trim().split(/\s+/);

        if (words.length <= maxWords) {
            setText(inputValue);
        } else {
            // Optionally, you can provide feedback to the user when the limit is exceeded
            alert('Maximum word limit reached!');
        }
    };
    const contactAction = () => {


        if (email.length === 0 || fullName.length === 0 || text.length === 0) {
            alert("all fields are required")
            return;
        }


        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            alert("Email format is invalid")
            return;
        }
        var body =
        {
            "full_name": fullName,
            "email": email,
            "message": text
        }


        try {
            setLoading(true)
            const config = {
                headers: {
                    "Content-Type": "application/json",

                },
            }; axios.post(`${baseUrl}/forms/callback-request`, body, config)
                .then((res) => {
                    console.log(res)
                    setLoading(false)
                    setShowSuccess(true)

                })
                .catch((error) => {
                    setLoading(false)
                    console.log(error);
                    alert(error.response.data.message);


                });
        } catch (error) {
            console.log("error", error);
        }


    }
    return (
        <div className="contact-hero-container">
            <div className="contact-hero-content">



                <div className="contact-hero-content_left">
                    <div style={{ marginBottom: '80px' }} >
                        <h1>Contact Us</h1>
                        <h5>We will be happy to assist you.</h5>
                    </div>
                    {/* <div className="contact_icon_left">
                        <img src={img1} alt="icon" />
                        <div>
                            <p>Our Location</p>
                            <h6>124, Oyediran Estate, Lagos, Nigeria, 5432</h6>
                        </div>
                        </div> */}
                    <div className="contact_icon_left">
                        <img src={img2} alt="icon" />
                        <div>
                            <p>Our Phone number</p>
                            <h6>(+234) 817 607 4520</h6>
                        </div>
                    </div>
                    <div className="contact_icon_left">
                        <img src={img3} alt="icon" />
                        <div>
                            <p>Our Email Address</p>
                            <h6>intelligence@sonaroid.io</h6>
                        </div>
                    </div>

                </div>

                <div className="contact-hero-content_right">
                    {showSuccess ?
                        <>
                            <div className="quote-success">
                                <h4 style={{ marginBottom: "2px" }}>Message Received</h4>

                                <p>Our team will contact you in 2-3 business days.</p>
                                <div >
                                    <img src={coffee} alt="icon" />
                                </div>



                                <button style={{ width: '280px', marginTop: "0px" }} onClick={() => setShowSuccess(false)}>Done</button>
                            </div>
                        </>
                        :
                        <form action="">
                            <h4>Send us a message</h4>
                            <div className="contact-hero-name">
                                <div>
                                    <h6>Full name</h6>
                                    <input
                                        type="text"
                                        required
                                        placeholder="Your Full Name"
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <h6>Email</h6>
                                    <input
                                        type="email"
                                        required
                                        placeholder="Your Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>

                            </div>
                            <div className="contact-hero-texarea">
                                <h6>Message</h6>
                                <textarea
                                    value={text}
                                    onChange={handleChange}
                                    cols="30"
                                    rows="10"
                                    placeholder="Your message to Sonaroid Analytics"
                                    required
                                ></textarea>
                                <p>Maximum of 150 words</p>
                            </div>
                            {loading ?
                                <p style={{ textAlign: 'center' }}>please wait</p>
                                :
                                <button onClick={() => contactAction()}>Send message</button>
                            }
                        </form>
                    }
                </div>

                }
            </div>
        </div>
    )
}

export default ContactHero
