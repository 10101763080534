import React from 'react'
import './AboutHeader.css'
const AboutHeader = () => {
    return (
        <div className="about_header">
            <h2>About Us</h2>
            <p>We are changing the <span style={{ color: '#757575' }}>narrative of African energy.</span></p>
        </div>
    )
}

export default AboutHeader
