import React, { useState, useEffect } from 'react';
import Slide1 from '../../../../assets/Slide1.jpg';
import Slide2 from '../../../../assets/Slide2.jpg';
import Slide3 from '../../../../assets/Slide3.jpg';
import Slide4 from '../../../../assets/Slide4.jpg';
import './Carousel.css'
import Slide5 from '../../../../assets/Slide5.jpg';
const images = [
    Slide1,
    Slide2,
    Slide3,
    Slide4,
    Slide5
];

const Carousel = () => {
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            // Move to the next image. Loop back to the first image when reaching the end.
            setCurrentImage(current => (current + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, []);

    const handleIndicatorClick = index => {
        setCurrentImage(index);
    };

    return (

        <div className="carousel">
            <div className="slide">
                <img src={images[currentImage]} alt={`Slide ${currentImage}`} />
            </div>
            <div className="indicators">
                {images.map((image, index) => (
                    <button
                        key={index}
                        className={index === currentImage ? 'indicator active' : 'indicator'}
                        onClick={() => handleIndicatorClick(index)}
                    />
                ))}
            </div>
            <div className="carousel-card">
                <h2>
                    More than just a data company
            </h2>
                <h5>
                    We also provide access to vetted reports, analytics, maps
                    and dashboards that take data and convert them to insights.
            </h5>
            </div>
        </div>



    );
};

export default Carousel;
