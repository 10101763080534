import React from 'react'
import './ServicesBody.css'
import GetQuote from '../../baseComponents/GetQuote';
const ServicesBody = () => {
    return (
        <div className="service-body-cont">
            <div className="service-body-header">
                <h1>Sonaroid handles your data and analytics
                projects, so you can focus on results.</h1>
            </div>


            <GetQuote />

            <div className="service-body-boxes">
                <div>
                    <small>Research</small>
                    <h4>Market Intelligence</h4>
                    <p> We step into uncharted territories for our clients.
                        Let us help you understand your market so you can make informed investment decisions and maximize returns.
                    </p>
                </div>
                <div>
                    <small>Analyze</small>
                    <h4>Data Analytics</h4>
                    <p>Your business generates tons of data but you don't know what to do with it?
                         With our world class analytics, we can help you convert that data to gold.
                    </p>
                </div>
                <div>
                    <small>Predict</small>
                    <h4>Predict</h4>
                    <p>Our feasibility studies provide a comprehensive evaluation of project viability,
                         identifying potential risks and opportunities for success.
                    </p>
                </div>

            </div>
        </div>
    )
}

export default ServicesBody
