import React from 'react'
import { IoIosArrowRoundForward } from "react-icons/io";
import EarlyAccessBtn from '../../baseComponents/EarlyAccessBtn';
import './Hero.css';
import hero from '../../../assets/heroImage.png'
const Hero = () => {
    return (
        <section>
            <div className="hero-container">
                <h1>Data. Africa. Energy.</h1>
                <h1>Find the resources you need.</h1>
                <p>
                    Authentic data and deep insights in Africa's energy industry
                </p>
                <div className="hero-access">

                    <EarlyAccessBtn
                        height='56px'
                        padding="0em 2.25em"
                    />

                    <div>
                        <a href="/services">Learn more</a>
                        <IoIosArrowRoundForward style={{ fontSize: '30px', color: 'rgba(255, 255, 255, 1)' }} />
                    </div>
                </div>
                <div className="hero-img">
                    <img src={hero} alt="heroimage" />
                </div>

            </div>
            <div className="hero-bottom">

            </div>
        </section>
    )
}

export default Hero
