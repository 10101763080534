import React from 'react'
import Navbar from '../Navbar/Navbar';
import ProblemSection from './ProblemSection/ProblemSection';
import SolutionSection from './SolutionSection/SolutionSection';
import Insights from './Insights/Insights';
// import Pricing from './Pricing/Pricing';
import Faq from './FAQ/Faq';
import GetStarted from './GetStarted/GetStarted';
import Footer from '../Footer/Footer';
import Hero from './Hero/Hero';
import { Helmet } from 'react-helmet-async'

const LandingPage = () => {
    return (
        <div>
            <Helmet>
                <title>Home</title>
                <meta name="description" content="Sonaroid website Authentic data and deep insights in Africa's energy industry" />
                <link rel="canonical" href="https://sonaroid.io/" />
            </Helmet>
            <Navbar
                background="#020712"
                alternative={null}
                bottomBorder="1px solid rgba(39, 43, 50, 1)"
            />
            <Hero />
            <ProblemSection />
            <SolutionSection />
            <Insights />
            {/* <Pricing /> */}
            <Faq />
            <GetStarted />
            <Footer />
        </div>
    )
}

export default LandingPage
